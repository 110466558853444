<template>
    <div>
        <label>
            <div class="search">
                <!--  搜索框，搜索按钮的美化
                明天进行下拉提示的美化，
                以及添加清空内容的功能，清空按钮美化-->

                <input class="search-input"
                       v-on:keyup.up="selectTip(-1)"
                       v-on:keyup.down="selectTip(1)"
                       v-on:keyup.enter="selectEnter"
                       v-on:keydown.tab="inputTab"
                       @blur="blurHandler"
                       @click="blurStyle=false"
                       type="text" v-model="value">
                <ul class="drop_down_menu" v-show="list.length>0&&!blurStyle">
                    <!--                <ul class="drop_down_menu" v-show="true">-->
                    <template v-for="(str,index) in list">
                        <template v-if="index===0">
                            <li @click="selectClick(str)" style="border-radius: 10px 10px 0 0" class="selected"
                                v-if="select===index">{{str}}
                            </li>
                            <li @click="selectClick(str)" style="border-radius: 10px 10px 0 0" class="default" v-else>
                                {{str}}
                            </li>
                        </template>
                        <template v-else-if="index===list.length-1">
                            <li @click="selectClick(str)" style="border-radius:0 0 10px 10px " class="selected"
                                v-if="select===index">{{str}}
                            </li>
                            <li @click="selectClick(str)" style="border-radius:0 0 10px 10px " class="default" v-else>
                                {{str}}
                            </li>
                        </template>
                        <template v-else>
                            <li @click="selectClick(str)" class="selected" v-if="select===index">{{str}}</li>
                            <li @click="selectClick(str)" class="default" v-else>{{str}}</li>
                        </template>

                    </template>
                </ul>
                <div class="search-clear" @click="()=>{
                this.selectTip(0)
                this.isUpdate = true
                this.lastValue = this.value
                this.list = []
                this.value = '' }">
                    <div class="icon-clean"></div>
                </div>
            </div>
            <button class="search-btn" style="display: inline-block" @click="selectEnter">提交</button>
        </label>
        <hr class="hr-interval">
    </div>
</template>
<script>
    import {jsonp} from 'vue-jsonp'

    export default {
        name: 'Handler',
        data() {
            return {
                blurStyle: false,
                url: "https://www.gujiheimao.com/api/sp0_baidu",
                value: "",
                isUpdate: true,
                lastValue: "",
                list: [],
                select: -1,
                selectUrl: "https://www.baidu.com"
            }

        },
        methods: {
            blurHandler() {
                this.blurStyle = true
            },
            async jsonpGet(url) {
                jsonp(url, {
                    cb: "search",
                    wd: this.value,
                    callback: "search"
                }).catch(error => {
                })
            },
            checkValue() {
                setTimeout(null, 200)
                if (this.value.trim().length !== 0) {
                    this.jsonpGet(this.url)
                }
            },
            //tab选择提示
            inputTab() {
                if (this.select === -1) {
                    return
                }
                this.isUpdate = true
                this.value = this.lastValue = this.list[this.select]
                this.select = -1
                this.list = []
            },
            selectTip(index) {
                this.isUpdate = false
                this.select += index
                if (this.select === -1) {
                    this.value = this.lastValue
                } else {
                    this.value = this.list[this.select]
                }

                if (this.select >= this.list.length) {
                    this.select = -1
                } else if (this.select < -1) {
                    this.select = this.list.length - 1
                }
            },
            searchValue() {
                this.select = -1
                if (this.value === null) {
                    window.open(this.selectUrl)
                } else {
                    window.open(this.selectUrl + '/s?wd=' + this.value)
                }
            },
            selectEnter() {
                this.selectTip(0)
                this.isUpdate = true
                this.lastValue = this.value
                this.list = []
                this.searchValue()
            },
            selectClick(str) {
                this.isUpdate = false
                this.value = str
                this.lastValue = this.value
                this.list = []
                this.searchValue()
            },
        },
        mounted: function () {
            // 将callBackMoon方法绑定到window对象
            window['search'] = (data) => {
                this.lastValue = this.value
                this.list = data.s
            }
        },
        watch: {
            value() {
                if (this.isUpdate) {
                    if (this.value !== this.lastValue) {
                        this.checkValue()
                    }
                }

            }
        }
    }
</script>
<style lang="scss" scoped>

    @media screen and (max-width: 800px) {

        div {
            margin: 5px 0 5px 0;
        }

        .hr-interval {
            height: 10px;
            border-color: transparent;
        }
    }

    @media screen and (min-width: 801px) and (max-width: 1200px) {

        div {
            margin: 15px 0 25px 0;
        }

        .hr-interval {
            height: 35px;
            border-color: transparent;
        }
    }

    @media screen and (min-width: 1201px) {

        div {
            margin: 35px 0 50px 0;
        }

        .hr-interval {
            height: 50px;
            border-color: transparent;
        }
    }

    @media screen and (min-width: 501px) {
        .search-input {
            width: 380px;
        }
    }

    @media screen and (min-width: 400px) and (max-width: 500px) {
        .search-input {
            width: 280px;
        }
    }


    div {
        .search-btn {
            outline: 0;
            height: 40px;
            width: 75px;
            margin: 0;
            padding: 2px;
            border-style: solid;
            border-width: 2px 2px 2px 0;
            border-radius: 0 15px 15px 0;
            font-size: 1.12em;
            color: darkblue;
            border-color: #42b983;
            vertical-align: middle;
            transition: 800ms background-color;
        }

        .search-btn:hover {
            background-color: #4dd497;
        }

        .search {
            display: inline-block;
            border-style: solid;
            border-width: 0;
            border-radius: 10px;
            /*box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.35);*/
        }

        .search-clear {
            width: 24px;
            height: 24px;
            margin: 8px 0 0 -25px;
            display: inline-block;
            position: absolute;
            /*background-color: rgba(0, 0, 0, 0.1);*/
            border-radius: 50%;
            z-index: 1;
            transition: 400ms background-color;
        }

        .search-clear .icon-clean {
            width: 20px;
            height: 20px;
            margin: 2px;
            background-size: 100% 100%;
            /*background-image: url("../../public/icon-clear.png");*/
        }

        .search-clear:hover {
            background-color: rgba(100, 100, 100, 0.2);
        }

        .search-input {
            padding: 0 0 0 14px;
            margin: 0;
            height: 36px;
            /*width: 380px;*/
            background-color: rgba(255, 255, 255, 0.6);

            font-size: 1.12em;

            border-color: #8bab9b;
            border-style: solid;
            border-radius: 15px 0 0 15px;
            border-width: 2px 0 2px 2px;
            z-index: 5;
            /*高亮*/
            outline: 0;
            vertical-align: middle;
            transition: 380ms border-color, 380ms background;
            &:focus {
                border-color: #42b983;
                transition: 200ms border-color, 200ms background;
                background-color: rgba(255, 255, 255, 0.9);
            }
        }


        ul {
            background-color: transparent;
            list-style: none;
            margin: 0;
            padding: 0;
            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);

            transition: 1000ms height;


        }


        li, .default {
            backdrop-filter: blur(5px);

            text-align: left;
            padding: 8px 0 8px 10px;
            background-color: rgba(255, 255, 255, 0.5);
        }

        li:hover, .selected {
            backdrop-filter: blur(0);
            background-color: rgba(255, 255, 255, 0.8);
        }


        .drop_down_menu {
            margin-top: 2px;
            float: left;
            display: block;
            position: absolute;
            /*top: 40px;*/
            /*background-color: rgba(100, 100, 100, 0.1);*/
            border-width: 2px;
            border-style: solid;
            border-color: #42b983;
            border-radius: 15px 15px;
            text-align: center;
            width: 393px;
            z-index: 1;
        }
    }


</style>
