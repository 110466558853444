<template>
    <div class="field">
    </div>
</template>

<script>
    export default {
        name: "pop-up-notification",
        data() {
            return {}
        }, methods: {

        }
    }
</script>

<style scoped lang="scss">
    .field{
        display: block;
        position: absolute;
        top: 0;

        width: 400px;
        height: 280px;
        backdrop-filter: blur(2px);
        background-color: rgba(136,35,175,0.3);
    }
</style>