<template>
    <nav>
        <new-search/>
        <div  class="box">
            <div class="background"></div>
            <div class="data" style="padding: 0;margin: 0;border: 0">
            <url-box/>
            </div>
        </div>
        <bottom class="bottom-content"/>
    </nav>
</template>
<script>
    import search from "./components/search.vue"
    import newSearch from "./components/new-search.vue"
    import urlBox from "./components/url-box.vue"
    import bottom from "./page/bottom";
    import popUpNotification from "./components/pop-up-notification"

    export default {
        name: "app",
        components: {search, urlBox, bottom, newSearch, popUpNotification}
    }

</script>
<style lang="scss">
    #app {
        min-width: 410px;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
    .sroll::-webkit-scrollbar, .head::-webkit-scrollbar {
        width: 0 !important
    }

    @media screen and (max-height: 1200px) {
        .nav-top {
            /*display:none;*/
            padding: 10px
        }
    }

    @media screen and (min-height: 1201px) {
        .nav-top {
            /*display:none;*/
            padding: 60px
        }
    }

    nav {
        height: auto;

        margin: 0;
        padding: 0;
        background-size: cover;
        min-height: 100vh;
        background: url("../public/background.jpg") no-repeat fixed;

    }
</style>
