<template>
    <div class="field">
        <form class="search" target="_blank" :action="list[select].url">
            <!--            搜索引擎切换，输入框，按钮，设置==-->
            <div class="sl-menu search-default">
                <p>百度</p>
                <div v-for="(data,i) in list">
                    <div class="menu-li menu-li-end" @click="selectEngine(i)" v-if="i===list.length-1">
                        {{data.name}}
                    </div>
                    <div class="menu-li menu-li-head" @click="selectEngine(i)" v-else-if="i===1">
                        {{data.name}}
                    </div>
                    <div class="menu-li menu-li-default" @click="selectEngine(i)" v-else>
                        {{data.name}}
                    </div>
                </div>
            </div>
            <div style="padding: 0;margin: 0;display: inline-block">
                <label>
                    <input class="sl-input search-default"
                           v-model="query"
                           autocomplete="off"
                           v-on:keyup.up="tipSelectMethod(-1)"
                           v-on:keyup.down="tipSelectMethod(1)"
                           v-on:keydown.enter="tipSelectEnter"
                           v-on:keydown.tab="tipSelectTab"
                           name="wd"
                           @blur="blur"
                           @click="blurStyle=true"/>

                </label>
                <div class="search-tip" style="position: absolute" v-show="s.length>0&&blurStyle">
                    <div class="search-tip-value" v-for="(data,index) in s">
                        <div @click="clickTip(index)" v-if="index!==tipSelect">
                            <p class="tip-font">{{data}}</p>
                        </div>
                        <div @click="clickTip(index)" class="tip-select" v-if="index===tipSelect">
                            <p class="tip-font-select">{{data}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="icon-clear" v-show="query.length!==0" @click="query=''">
                <svg class="icon" viewBox="0 0 1024 1024"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M226.31 256.831l30.165-30.165 541.18 541.18-30.166 30.164z"
                          fill="#707070">
                    </path>
                    <path d="M226.318 767.845l541.179-541.179 30.164 30.165-541.179 541.18z"
                          fill="#707070">
                    </path>
                </svg>
            </div>
            <div id="search" class="sl-submit search-default" @click="openNewPage">搜索</div>
        </form>
    </div>
</template>

<script>
    import {jsonp} from "vue-jsonp";

    export default {
        name: "new-search",
        data() {
            return {
                //选择的数组序下标
                select: 0,
                blurStyle: true,
                list: [
                    {
                        //搜索网站名称
                        name: '百度',
                        //跳转网址
                        url: 'https://www.baidu.com/s',
                        //提示网址
                        tipUrl: 'https://www.gujiheimao.com/api/sp0_baidu'
                    }
                ],
                tipSelect: -1,
                query: '',
                isUpdate: true,
                s: []
            }
        },
        methods: {
            blur() {
                setTimeout(() => {
                    console.log('-----');
                    this.blurStyle = false
                }, 100)
            },
            selectEngine(index) {
                console.log(index)
                this.select = index
            },
            clickTip(index) {
                this.query = this.s[index]
                this.s = []
                this.tipSelect = -1
                this.openNewPage()
            },
            tipSelectMethod(index) {//提示选择
                console.log(this.tipSelect)
                if (this.tipSelect >= this.s.length) {
                    this.tipSelect = 0
                } else if (this.tipSelect < -1) {
                    this.tipSelect = this.s.length - 1
                } else {
                    this.tipSelect = this.tipSelect + index
                }

                console.log(this.tipSelect)
            },
            tipSelectEnter() {// 回车搜索数据
                if (this.tipSelect === -1) {
                    return
                } else {
                    this.query = this.s[this.tipSelect]
                    this.s = []
                    this.tipSelect = -1
                }
            },
            tipSelectTab() {//选择提示 tab选中
                if (this.tipSelect !== -1) {
                    this.query = this.s[this.tipSelect]
                }
                this.tipSelect = -1
                this.s = []
            },
            openNewPage() {//打开新页面
                console.log("===========")
                console.log(this.list[this.select].url + "?wd=" + this.query)
                window.open(this.list[this.select].url + "?wd=" + this.query)
            },
            async jsonpGet(url) {//请求地址
                jsonp(url, {
                    cb: "search",
                    wd: this.query,
                    callback: "search"
                }).catch(error => {
                })
            },
            implementSearchTip() {
                this.jsonpGet(this.list[this.select].tipUrl)
            }
        },
        mounted: function () {
            // 将callBackMoon方法绑定到window对象
            window['search'] = (data) => {
                console.log(data.s)
                this.s = data.s
            }
        },
        watch: {
            query() {
                if (this.query.length === 0) {
                    this.s = []
                } else if (this.isUpdate) {

                    this.isUpdate = true
                    this.implementSearchTip()
                } else {
                    this.isUpdate = true
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .field {

        z-index: 1;
        position: relative;
        margin: 0;
        padding: 0;
        border: 0;
    }

    @media screen and (min-width: 400px) {
        .field {
            height: 280px;
            width: 100%;
            /*background-color: rgba(91, 205, 151, 0.3);*/
            margin: 0 0 30px 0;

            .search {
                padding-top: 80px;
                display: inline-block;
            }

            .search-default {
                line-height: 210%;
                padding: 0;
                vertical-align: middle;
                height: 40px;
                display: inline-block;
                border-style: solid;
                border-width: 1px 0;
                outline: 0;
                border-color: #000000;
            }

            .sl-menu {
                width: 80px;

                font-size: 1.2em;
                font-weight: bold;


                background-color: #FFF;
                border-width: 1px 0.01em 1px 1px;
                border-style: solid;
                border-radius: 15px 0 0 15px;

                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                p {
                    padding: 0;
                    margin: 0;
                }

                &:hover {
                    border-radius: 15px 0 0 0;

                    .menu-li {
                        display: block;
                    }

                }


                .menu-li {
                    line-height: 170%;
                    margin-left: -2px;
                    display: none;
                    position: relative;
                    width: 100%;
                    height: 30px;
                    background-color: #EEE;
                    z-index: 1;

                    &:hover {
                        background-color: #CCC;
                    }
                }

                .menu-li-head {
                    border-style: solid;
                    border-width: 1px 2px 0 2px;
                }

                .menu-li-default {
                    border-style: solid;
                    border-width: 1px 2px 0 2px;
                }

                .menu-li-end {
                    height: 34px;
                    border-style: solid;
                    border-width: 1px 2px 1px 2px;
                    border-radius: 0 0 15px 15px;
                }

            }

            .sl-input {
                z-index: 5;
                font-size: 1.2em;
                padding: 0 0 0 10px;
            }

            .search-tip {
                height: auto;
                position: relative;
                z-index: 1;

                .search-tip-value {
                    & div {
                        height: 36px;
                        backdrop-filter: blur(5px);
                        -ms-backdrop-filter:blur(5px);
                        -moz-backdrop-filter:blur(5px);
                        -o-backdrop-filter:blur(5px);
                        -webkit-backdrop-filter:blur(0);
                        background-color: rgba(255, 255, 255, 0.6);
                        margin: 0;
                        padding: 0;
                    }

                    .tip-font {
                        padding: 5px 0 2px 10px;
                        text-align: left;
                        margin: 0;
                        text-shadow: 1px 1px 1px black,-1px -1px 1px rgb(255,255,255);
                        font-size: 1.12em;
                        margin-block-start: 0;
                        margin-block-end: 0;
                    }

                    .tip-font-select {
                        padding: 5px 0 2px 10px;
                        text-align: left;
                        margin: 0;
                        font-size: 1.12em;
                        margin-block-start: 0;
                        margin-block-end: 0;
                        z-index: 3;
                    }

                    & div:hover, .tip-select {
                        backdrop-filter: blur(0);
                        -ms-backdrop-filter:blur(0);
                        -moz-backdrop-filter:blur(0);
                        -o-backdrop-filter:blur(0);
                        -webkit-backdrop-filter:blur(0);
                        filter: blur(0);
                        background-color: rgba(255, 255, 255, 0.5);
                    }
                }
            }

            .sl-submit {
                transition: 300ms all;
                font-weight: bold;
                font-size: 1.2em;

                background-color: #44d89a;
                width: 80px;

                border-radius: 0 15px 15px 0;
                border-width: 1px 1px 1px 0;

                /*禁止选中*/
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                &:hover {
                    background-color: #5fdba0;
                    color: #FFF;
                }

            }

            .icon-clear {
                height: 25px;
                width: 25px;
                position: absolute;
                z-index: 2;
                display: inline-block;
                margin: 8.5px 0 8.5px -25px;
                padding: 0;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.08);

                &:hover {
                    background-color: rgba(0, 0, 0, 0.18);
                }
            }
        }
    }

    @media screen and (min-width: 580px) {
        .search {
            width: 540px;
            padding-top: 20px;
        }
        .sl-input {
            width: 350px;
        }
        .search-tip {
            width: 360px;
        }
    }

    @media screen and (min-width: 400px) and (max-width: 579px) {
        .search {
            width: 400px;
            padding-top: 10px;
        }
        .sl-input {
            width: 200px;
        }
        .search-tip {
            width: 210px;
        }
    }

</style>