<template>
    <div>
        <div class="link">
            <div class="link-middle" v-show="link">
                <svg class="icon" @click="link=false" viewBox="0 0 1024 1024"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M226.31 256.831l30.165-30.165 541.18 541.18-30.166 30.164z"
                          fill="#808080">
                    </path>
                    <path d="M226.318 767.845l541.179-541.179 30.164 30.165-541.179 541.18z"
                          fill="#808080">
                    </path>
                </svg>
                <p>作者:孤寂黑猫</p>
                <p>邮箱:1842520361@qq.com</p>
            </div>
        </div>
        <a target="_blank" :href="url_1">
            <img src="../../public/filingIcon.png"/>
            <p>粤公网安备 44010602010000号</p>
        </a>
        <br class="br">
        &nbsp;
        &nbsp;
        <a target="_blank" :href="url_2">
            <p>粤ICP备2022009414号-1</p>
        </a>
        &nbsp;
        &nbsp;
        <a target="_blank" @click="tips">
            <p>联系作者</p>
        </a>
    </div>
</template>

<script>
    export default {
        name: "bottom",
        data() {
            return {
                url_1: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010000',
                url_2: 'https://beian.miit.gov.cn/',
                link: false
            }
        },
        methods: {
            tips() {
                this.link = !this.link;
            }

        }
    }
</script>

<style scoped lang="scss">

    @media screen and (min-width: 801px) {
        a {
            display: inline-block;
        }
        .br {
            display: none;
        }

        .link {
            margin-bottom: 40px;
        }
    }

    @media screen and (max-width: 800px) {
        a {
            display: inline-block;
        }

        .link {
            margin-bottom: 63px;
        }
    }


    div {
        position: fixed;
        bottom: 0;

        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(5px);
        box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.2);

        width: 100%;
        padding: 10px 0 5px 0;

        .link {

            backdrop-filter: blur(0);

            .link-middle {
                p {
                    margin: 0;
                    padding: 0;
                }

                .icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 30px;
                    height: 30px;

                    &:hover{
                        background-color: rgb(22,22,22,0.3);
                        border-radius: 0 15px 0 15px;
                        color: #fff;
                    }
                }

                border-radius: 15px;
                background-color: rgba(255, 255, 255, 0.5);
                position: absolute;
                font-size: 1.3em;
                text-shadow: black;
                /*background-color: #EEEEEE;*/
                width: 300px;
            }
        }

        a {
            img {
                float: left;
            }

            p {
                text-shadow: 1px 1px black;
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0 0 0 5px;
                color: #939393;

                &:hover {
                    color: #dfdcdc;
                }
            }

            text-decoration: none;
            height: 20px;
            /*line-height: 20px;*/

        }

    }
</style>