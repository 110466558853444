<template>
    <div class="field">
        <div class="list-box">
            <div class="box" v-for="(data,boxNum) in dateList">
                <div class="box-head">
                    <p>
                        {{data.head}}
                    </p>
                </div>
                <div class="box-body">
                    <template v-for="(menuData,menuNum) in data.menu">
                        <div class="box-menu" style="cursor: pointer;" @click="open(menuData.href)">
                            {{menuData.name}}
                        </div>
                    </template>
                </div>

            </div>

        </div>
<!--        <div class="background"></div>-->
    </div>
</template>

<script>
    export default {
        name: "url-box",
        data() {
            return {
                dateList: [
                    {
                        head: '学习 博客',
                        menu: [
                            {
                                name: '菜鸟教程',
                                href: "https://www.runoob.com/"
                            },
                            {
                                name: 'w3cSchool',
                                href: "https://www.w3school.com.cn/"
                            },
                            {
                                name: 'github',
                                href: "https://github.com/"
                            },
                            {
                                name: 'gitee',
                                href: "https://gitee.com/"
                            },
                            {
                                name: 'CSDN',
                                href: "https://blog.csdn.net"
                            },
                            {
                                name: '博客园',
                                href: "https://www.cnblogs.com/"
                            },
                            {
                                name: '简书',
                                href: "https://www.jianshu.com"
                            },
                            {
                                name: '思否',
                                href: "https://segmentfault.com"
                            },
                            {
                                name: '掘金',
                                href: "https://juejin.cn"
                            }
                        ]
                    },
                    {
                        head: '影音 娱乐',
                        menu: [
                            {
                                name: '哔哩哔哩',
                                href: "https://www.bilibili.com/"
                            },
                            {
                                name: '腾讯视频',
                                href: "https://v.qq.com/"
                            },
                            {
                                name: '爱奇艺',
                                href: "https://www.iqiyi.com/"
                            },
                            {
                                name: '网易云音乐',
                                href: "https://music.163.com/"
                            },
                            {
                                name: 'QQ音乐',
                                href: "https://y.qq.com/"
                            },
                            {
                                name: '酷狗',
                                href: "https://www.kugou.com/"
                            },
                            {
                                name: '起点中文网',
                                href: 'https://www.qidian.com/'
                            },
                            {
                                name: '创世中文网',
                                href: 'https://www.maigoo.com/brand/37295.html'
                            },
                            {
                                name: '晋江',
                                href: 'http://www.jjwxc.net/'
                            }

                        ]
                    },
                    {
                        head: '社区 工具',
                        menu: [
                            {
                                name: '微博',
                                href: "https://weibo.com/"
                            },
                            {
                                name: '百度贴吧',
                                href: "https://tieba.baidu.com/"
                            },
                            {
                                name: '豆瓣',
                                href: "https://www.douban.com/"
                            },
                            {
                                name: '百度翻译',
                                href: "https://fanyi.baidu.com/"
                            },
                            {
                                name: '有道云',
                                href: "https://fanyi.youdao.com/"
                            },
                            {
                                name: '谷歌翻译',
                                href: "https://translate.google.cn/"
                            },
                            {
                                name: '在线PS',
                                href: "https://www.photopea.com/"
                            },
                            {
                                name: 'qq邮箱',
                                href: "https://mail.qq.com/"
                            },
                            {
                                name: '网易邮箱',
                                href: "https://mail.163.com/"
                            }
                        ]
                    },
                    {
                        head: '新闻 资讯',
                        menu: [
                            {
                                name: '央视网',
                                href: "https://news.cctv.com/"
                            },
                            {
                                name: '凤凰资讯',
                                href: "https://news.ifeng.com/"
                            },
                            {
                                name: 'qq新闻',
                                href: "https://news.qq.com/"
                            },
                            {
                                name: '中关村',
                                href: "https://m.zol.com.cn/"
                            },
                            {
                                name: '澎湃新闻',
                                href: "https://www.thepaper.cn/"
                            },
                            {
                                name: '小鸡词典',
                                href: "https://jikipedia.com/"
                            },
                            {
                                name: '知微事见',
                                href: "https://ef.zhiweidata.com/"
                            },
                            {
                                name: '新浪新闻',
                                href: "https://www.sina.com.cn/"
                            },
                            {
                                name: '新华网',
                                href: "http://www.xinhuanet.com/"
                            }
                        ]
                    },
                    {
                        head: '游戏',
                        menu: [
                            {
                                name: 'steam',
                                href: "https://store.steampowered.com/"
                            },
                            {
                                name: 'epic',
                                href: "https://store.epicgames.com/zh-CN/"
                            },
                            {
                                name: '3DM',
                                href: "https://www.3dmgame.com/"
                            },
                            {
                                name: '4399',
                                href: "https://www.4399.com/"
                            },
                            {
                                name: '游民星空',
                                href: "https://www.gamersky.com/"
                            },
                            {
                                name: '7k7k',
                                href: "https://www.7k7k.com/"
                            },
                            {
                                name: '游侠',
                                href: "https://www.ali213.net/"
                            },
                            {
                                name: 'mcbbs',
                                href: "https://www.mcbbs.net/"
                            }
                        ]
                    },
                    {
                        head: '购物',
                        menu: [
                            {
                                name: '拼多多',
                                href: "https://www.pinduoduo.com/"
                            },
                            {
                                name: '天猫',
                                href: "https://www.tmall.com/"
                            },
                            {
                                name: '淘宝',
                                href: "https://www.taobao.com/"
                            },
                            {
                                name: '京东',
                                href: "https://www.jd.com/"
                            },
                            {
                                name: '阿里巴巴',
                                href: "https://www.1688.com/"
                            },
                            {
                                name: '唯品会',
                                href: "https://www.vip.com/"
                            },
                            {
                                name: '腾讯云',
                                href: "https://www.3dmgame.com/"
                            },
                            {
                                name: '阿里云',
                                href: "https://www.4399.com/"
                            },
                            {
                                name: '华为云',
                                href: "https://www.huaweicloud.com/"
                            }
                        ]
                    }
                ]
            }
        },
        methods: {
            open(url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @media screen and (min-width: 400px) {

        .field {
            margin-bottom: 80px;
            backdrop-filter: blur(8px);
            display: inline-block;

            box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.5);
            color: white;
            .list-box {
                margin: 0 0;
                width: 400px;
                height: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                background-color: transparent;

                .box {
                    border-color: rgba(255, 255, 255, 0.1);
                    border-style: solid;
                    height: 170px;
                    width: 350px;
                    /*display: inline-block;*/
                    margin: 10px 22px;
                    background-color: rgba(255, 255, 255, 0.05);

                    .box-head {
                        font-size: 1.18em;
                        padding-left: 15px;
                        text-align: left;
                        border-style: solid;
                        border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
                        border-width: 0 15px 2px 15px;
                    }

                    .box-head p {
                        margin: 0;
                        padding: 0;
                        text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
                    }

                    .box-body {
                        /*禁止文字选中*/
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;

                        height: 144px;
                        background-color: rgba(186, 186, 186, 0.01);
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-evenly;
                    }

                    .box-menu {

                        /*filter: invert(50%);*/
                        backdrop-filter: blur(50px);
                        height: 33px;
                        width: 108px;
                        padding: 4px 0;
                        background-color: rgba(0, 0, 0, 0.55);
                        transition: 320ms font-size;
                        line-height: 35px;
                        text-align: center;
                        font-weight: bold;
                        border-radius: 5px;
                        border: rgba(0, 0, 0, .35) solid 1px;
                    }

                    .box-menu:hover {

                        border: #42b983 solid 1px;
                        font-size: 1.3em;
                        height: 33px;
                        width: 108px;
                        background-color: rgba(0, 0, 0, 0.65);
                    }
                }
            }
        }
    }

    @media screen and (min-width: 801px) and (max-width: 1200px) {
        .field {


            backdrop-filter: blur(8px);
            display: inline-block;
            box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.5);
            color: white;

            .list-box {
                margin: 0 0;
                width: 800px;
                height: auto;
            }
        }
    }

    @media screen and (min-width: 1201px) {
        .field {
            .list-box {
                margin: 30px 0;
                width: 1200px;
                height: 380px;
            }

        }
    }

</style>